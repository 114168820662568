import Image from "next/image";
import "swiper/css";
import "swiper/css/pagination";

import Marquee from "react-fast-marquee";

const Clients = () => {
  return (
    <div className="relative flex flex-col space-y-10 md:mt-10  max-w-screen-xxl mx-auto">
      {/* <Animate.ScaleIn> */}
        {/* <div className="flex flex-col items-center space-y-1 mt-2">
          <span className={`${kumbhsans.className} font-bold text-2xl text-secondary`}>
            Our Clients
          </span>
          <p className={`${kumbhsans.className} font-bold text-2xl md:text-[40px] text-primary`}>
            We Create Brands!
          </p> */}
          {/* <span className="text-base font-normal max-w-xl text-center md:mt-1">
            We create brands that resonate with target audiences and drive
            business success. We understand that a strong brand identity is
            essential for establishing a unique and memorable presence in the
            market.
          </span> */}
        {/* </div> */}
      {/* </Animate.ScaleIn> */}
      <Marquee speed={50} autoFill={true}> 
       <div className="grid grid-cols-12  justify-center justify-items-center gap-4 md:gap-2 xl:gap-0 xl:flex xl:flex-row xl:items-center xl:justify-center xl:space-x-12 mt-5 md:mt-0 ">
       <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one3.png"
          className="filter transition-all duration-300 max-w-[90px] max-h-[70px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
         <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one4.png"
          className="filter transition-all duration-300 max-w-[130px] max-h-[70px] mr-5"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />  <Image
          width="600"
          height="600"
          src="/images/clubfoot.png"
          alt={"out client navigotaxis"}
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
        <Image
          alt="our client wannabeedigital"
           width="600"
          height="600"
          src="/images/maglo.png"
          className="filter transition-all duration-300 max-w-[200px] max-h-[100px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
        <Image
          alt="our client kjc"
          width="600"
          height="600"
          src="/images/aifornia.png"
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
        <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/seller_metrics.png"
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
        <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/kutsbee.png"
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
            <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one6.jpg"
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
            <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one7.png"
          className="filter transition-all duration-300 max-w-[150px] max-h-[76px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
          <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one5.jpg"
          className="filter transition-all duration-300 max-w-[130px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
         <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one1.png"
          className="filter transition-all duration-300 max-w-[190px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
         <Image
          alt="out client boomaenterprises"
          width="600"
          height="600"
          src="/images/one2.png"
          className="filter transition-all duration-300 max-w-[130px] max-h-[86px]"
          style={{width: "100%", height: "100%" , objectFit:"contain"}}
        />
      
      </div>
      </Marquee>
  
      {/* <Image
        src={"/images/technologies_circle.svg"}
        width={100}
        height={100}
        className="absolute right-14 top-56 md:-top-10 -z-10 md:w-[487px] md:h-[487px]"
        alt="circle"
      />
      <Image
        src={"/images/client-ellipse.svg"}
        width={80}
        height={80}
        className="absolute md:left-36 -top-10 -z-10 md:2-[113px] md:h-[113px]"
        alt="circle"
      /> */}
    </div>
  );
};

export default Clients;
